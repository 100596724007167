// @ts-nocheck

import React from "react";
import styles from "../__styles__/ReviewFlow.module.scss";
import { useTestContext } from "../../contexts/test";
import { useProduct } from "../../hooks/useProduct";
import { useReportsContext } from "../../contexts/reports";

export default ({ productId }) => {
  const { product } = useProduct(productId);
  const { test } = useTestContext();
  const { completeReviewReportProductIds } = useReportsContext();

  return (
    <div className={styles.productOverview}>
      {test.productIds.length > 1 && (
        <div
          className={styles.count}
          style={{ textAlign: "center", width: "100%" }}
        >
          Product {completeReviewReportProductIds.length + 1} of{" "}
          {test.productIds.length}
        </div>
      )}
      <div className={styles.productCard}>
        <div className={styles.title}>
          <span
            className={styles.color}
            style={{ background: product ? product.get("color") : "none" }}
          ></span>{" "}
          {product ? product.get("name") : ""}
        </div>
        <div className={styles.description}>
          <p
            dangerouslySetInnerHTML={{
              __html: product
                ? product.get("description").replace(/\\\\?n/gi, "<br />")
                : "",
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

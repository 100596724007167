import React from "react";

import { IonFooter, IonToolbar } from "@ionic/react";
import styles from "./__styles__/Footer.module.scss";

export default ({ children, ...props }) => {
  return (
    <IonFooter className={styles.footer + " ion-no-border"} {...props}>
      <IonToolbar className={styles.toolbar}>{children}</IonToolbar>
    </IonFooter>
  );
};

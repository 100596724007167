import React from "react";
import { useFirebaseContext } from "../contexts/firebase";

export const useProduct = (productId) => {
  const { db } = useFirebaseContext();
  const [product, setProduct] = React.useState();

  React.useEffect(() => {
    return db.collection("products").doc(productId).onSnapshot(setProduct);
  }, [productId]);

  return { product };
};

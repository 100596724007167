// @ts-nocheck

import React from "react";
import moment from "moment";
import cx from "classnames";
import {
  IonContent,
  IonPage,
  IonSlides,
  IonSlide,
  IonButton,
  useIonViewWillEnter,
} from "@ionic/react";

import Header from "../components/Header";
import ProgressModal from "../components/ProgressModal";
import { useTestContext } from "../contexts/test";
import { useReportsContext } from "../contexts/reports";
import { useExams } from "../hooks/useExams";

import styles from "./__styles__/Progress.module.scss";

export default () => {
  const { test } = useTestContext();
  const {
    progressExams,
    activeExam,
    lastCompleteExam,
    futureExams,
    missedExams,
    upcomingExam,
  } = useExams();
  const sliderRef = React.useRef();

  useIonViewWillEnter(() => {
    if (!sliderRef?.current) return;

    let lastIndex = progressExams.indexOf(activeExam || lastCompleteExam || 0);

    sliderRef.current.slideTo(lastIndex, 0);
    sliderRef.current.update();
  }, [progressExams, activeExam, lastCompleteExam]);

  return (
    <IonPage>
      <Header link />
      <IonContent fullscreen forceOverscroll={false} className={styles.content}>
        <div className={styles.wrap}>
          <div className={styles.sliderWrap}>
            <div>
              <IonSlides
                pager={true}
                ref={sliderRef}
                options={{
                  centeredSlides: true,
                  spaceBetween: -150,
                  slideActiveClass: styles.slideActive,
                }}
              >
                {progressExams?.map((exam: any, index) => (
                  <ProgressSlide
                    key={exam.checkInKey}
                    exam={exam}
                    index={index}
                    active={activeExam === exam}
                    missed={missedExams.includes(exam)}
                    future={futureExams.includes(exam)}
                    upcoming={upcomingExam === exam}
                  />
                ))}
              </IonSlides>
            </div>
          </div>
          <div className={styles.infoGrid + " body-text"}>
            <div className={styles.row}>
              <div className={styles.col}>Body Part</div>
              <div className={styles.col}>{test?.trial?.bodyParts}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>Skin Concern</div>
              <div className={styles.col}>{test?.trial?.concerns}</div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const ProgressSlide = ({ exam, index, active, missed, upcoming, future }) => {
  const { getReportByExam } = useReportsContext();
  const [modalVisible, setModalVisible] = React.useState();
  const report = getReportByExam(exam);

  let status = "no-start";
  if (missed) status = "missed";
  if (active) status = "active";
  if (future) status = "future";
  if (upcoming) status = "upcoming";
  if (report?.completedAt) status = "completed";

  return (
    <IonSlide
      className={cx(styles.slide, {
        [styles.missed]: missed,
        [styles.active]: active,
        [styles.blank]: future && status !== "upcoming",
      })}
      onClick={() => {
        setModalVisible(true);
      }}
    >
      <div className={styles.slideInner + " body-text"}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            padding: 16,
          }}
        >
          <span style={{ marginBottom: 10, fontWeight: 500 }}>
            {exam.checkInKey !== "before"
              ? `Check-in ${index}`
              : `Pre-test status`}
          </span>
          {(exam?.startDate || report?.completedAt) && (
            <span>
              {moment(
                report?.completedAt.toDate() || exam?.startDate?.toDate()
              ).format("MMM D, YYYY")}
            </span>
          )}
        </div>
        <div
          style={{
            background: {
              completed: "#E6F3C5",
              missed: "#F5E2E2",
              upcoming: "#F6F8BB",
              active: "#F6F8BB",
              future: "#F6F6F6",
            }[status],
            padding: 16,
            borderTop: "1px solid #575B49",
            minHeight: "50%",
            textAlign: "left",
            lineHeight: 1.4,
          }}
        >
          {/* {!report?.photo && report?.emoji && (
            <div style={{ marginBottom: 10 }}>
              <EmojiInput readOnly value={report?.emoji} />
            </div>
          )}

          {!report?.photo && !report?.emoji && report?.rating && (
            <div style={{ marginBottom: 10 }}>
              <StarInput readOnly value={report?.rating} />
            </div>
          )}

          {report?.photo && (
            <div
              className={styles.img}
              style={{
                backgroundColor: "#333",
                backgroundImage: `url(${report?.photo})`,
                width: "100%",
                minHeight: 175,
              }}
            ></div>
          )} */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
              alignItems: "flex-end",
              textAlign: "left",
              lineHeight: 1.4,
            }}
          >
            <span style={{ fontWeight: 500 }}>Status:</span>
            <span>{`${
              {
                completed: "✅",
                missed: "❌",
                upcoming: "⏳",
                active: "⏳",
                future: "",
              }[status]
            }`}</span>
          </div>

          {status === "upcoming" && <div>Testing in progress.</div>}

          {status === "future" && <div>Not yet started.</div>}

          {status === "completed" && (
            <div>
              Completed.
              <br />
              <span style={{ textDecoration: "underline" }}>View check-in</span>
            </div>
          )}

          {status === "active" && (
            <IonButton
              routerLink={`/add-check-in/${exam.checkInKey}`}
              className={styles.button}
              style={{ width: "100%" }}
            >
              Add Update
            </IonButton>
          )}

          {missed && (
            <div>
              Check-in Missed
              <br /> No Update
            </div>
          )}
        </div>
      </div>
      {!!report?.completedAt && (
        <ProgressModal
          report={report}
          index={index}
          isOpen={!!modalVisible}
          swipeToClose={true}
          onDidDismiss={() => setModalVisible(false)}
        />
      )}
    </IonSlide>
  );
};

// @ts-nocheck

import React from "react";
import {
  IonButton,
  IonLabel,
  IonTextarea,
  IonLoading,
  IonToast,
  IonRadioGroup,
  IonItem,
  IonRadio,
} from "@ionic/react";

import StarInput from "../../components/StarInput";
import EmojiInput from "../../components/EmojiInput";
import PhotoInput from "../../components/PhotoInput";
import styles from "../__styles__/ReviewFlow.module.scss";
import useForm, { IS_REQUIRED } from "../../hooks/forms";

import ProductCard from "./ProductCard";

export default ({ exam, onSubmit, productId }) => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const quiz = exam.tasks;
  const notice = exam?.quizInstructions;
  const { get, set, validate, errors } = useForm(formToFormRules(quiz));

  const handleSubmit = async () => {
    setLoading(true);
    try {
      validate();
    } catch (err) {
      setError("Please answer all questions.");
      setLoading(false);
      return;
    }

    const results = (quiz || []).map((field, index) => {
      const formField = `${field.type}${index}`;
      return {
        ...field,
        index,
        id: formField,
        value: get(formField),
      };
    });

    await onSubmit({
      results,
    });

    setLoading(false);
  };

  return (
    <>
      <div className={styles.review}>
        {notice && <p className={styles.notice}>{notice}</p>}
        {productId && <ProductCard productId={productId} />}

        <div className={styles.form}>
          {(quiz || []).map((field, index) => {
            const formField = `${field.type}${index}`;

            return (
              <div className={styles.fieldset} key={index}>
                <IonLabel className={styles.label + " body-text"}>
                  {field.text}{" "}
                  {errors[formField] && (
                    <span className={styles.error}>Required</span>
                  )}
                </IonLabel>

                {(field.type === "textarea" || field.type === "text") && (
                  <IonTextarea
                    inputmode="text"
                    placeholder={`Your answer here. ${
                      field.required ? "" : "(Optional)"
                    }`}
                    className={styles.input}
                    autoGrow={true}
                    autocapitalize
                    rows={field.type === "textarea" ? 5 : 2}
                    value={get(formField)}
                    onIonChange={(evt) => set(formField)(evt.target.value)}
                    disabled={loading}
                  ></IonTextarea>
                )}

                {field.type === "star" && (
                  <StarInput
                    onChange={set(formField)}
                    value={get(formField)}
                    disabled={loading}
                  />
                )}

                {field.type === "emoji" && (
                  <EmojiInput
                    onChange={set(formField)}
                    value={get(formField)}
                    disabled={loading}
                  />
                )}

                {field.type === "photo" && (
                  <PhotoInput
                    onChange={set(formField)}
                    value={get(formField)}
                    disabled={loading}
                  />
                )}

                {field.type === "bool" && (
                  <IonRadioGroup
                    onIonChange={(e) => set(formField)(e.detail.value)}
                  >
                    <IonItem>
                      <IonLabel>Yes</IonLabel>
                      <IonRadio slot="start" value="yes" />
                    </IonItem>

                    <IonItem>
                      <IonLabel>No</IonLabel>
                      <IonRadio slot="start" value="no" />
                    </IonItem>
                  </IonRadioGroup>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.footer}>
        <IonButton
          onClick={handleSubmit}
          color="secondary"
          expand="block"
          disabled={loading}
          className={styles.button}
        >
          {exam.checkInKey === "review" ? "Submit Review" : "Save Status"}
        </IonButton>
      </div>
      <IonToast
        isOpen={!!error}
        onDidDismiss={() => setError("")}
        message={error}
        duration={700}
        header="Error"
        color="danger"
        position="top"
      />
      <IonLoading spinner="crescent" isOpen={loading} />
    </>
  );
};

const formToFormRules = (form) => {
  return form.map((field, index) => {
    return {
      name: `${field.type}${index}`,
      value: ["stars", "emojis"].includes(field.type) ? 0 : "",
      validation:
        field.required === true
          ? [
              {
                validator: IS_REQUIRED,
                message: "Required field",
              },
            ]
          : [],
    };
  });
};

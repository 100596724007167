import React from "react";
import { Route, Redirect } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonTabs,
} from "@ionic/react";
import cx from "classnames";
import { IonReactRouter } from "@ionic/react-router";
import { Plugins } from "@capacitor/core";

import { useFirebaseContext } from "./contexts/firebase";
import { useTestContext } from "./contexts/test";
import { useReportsContext } from "./contexts/reports";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/fonts.css";
import "./theme/variables.css";
import AppUrlListener from "./AppURLListener";

import Login from "./pages/Login";
import ReviewFlow from "./pages/ReviewFlow/ReviewFlow";
import Demo from "./pages/Demo";
import Setup from "./pages/Setup";
import InboxTab from "./pages/TabInbox";
import ProgressTab from "./pages/TabProgress";
import ProductsTab from "./pages/TabProducts";
import InfoTab from "./pages/TabInfo";
import CheckIn from "./pages/CheckIn/CheckIn";
import BeforeSuccess from "./pages/BeforeSuccess";
import NotFound from "./pages/NotFound";
import { useExams } from "./hooks/useExams";
import styles from "./pages/__styles__/Home.module.scss";

Plugins.Keyboard.setAccessoryBarVisible({ isVisible: true });

const App: React.FC = () => {
  const { initialized, user } = useFirebaseContext();
  const { test } = useTestContext();
  const { loaded: reportsLoaded, isExamComplete } = useReportsContext();
  const { beforeExam } = useExams();
  console.log({ test, user: user?.data() });

  if (!initialized) return null; // wait for user to load
  if (user?.get("enrolledTestId") && !test) return null; // wait for test to load
  if (test && !reportsLoaded) return null;

  const handleOnboardingFlow = () => {
    if (!user) return <Redirect to="/login" />;
    if (!user.get("enrolledTestId")) return <Redirect to="/setup" />;
    if (test && !test?.demoSeen) return <Redirect to="/demo" />;
    if (beforeExam && !isExamComplete(beforeExam))
      return <Redirect to="/add-check-in/before" />;

    return <Redirect to="/tabs" />;
  };

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener />
        <IonRouterOutlet animated={false}>
          <Route
            path="/login"
            exact
            render={() => (!user ? <Login /> : <Redirect to="/" />)}
          />
          <AuthRoute
            path="/setup"
            exact
            render={() =>
              !user.get("enrolledTestId") ? <Setup /> : <Redirect to="/" />
            }
          />
          <AuthRoute
            path="/demo"
            exact
            render={() => (!test?.demoSeen ? <Demo /> : <Redirect to="/" />)}
          />
          <AuthRoute
            path="/add-check-in/:initialCheckInKey/:initialProductId?"
            exact
            component={CheckIn}
          />
          <AuthRoute path="/review" component={ReviewFlow} />
          <Route path="/before-success" exact component={BeforeSuccess} />
          <AuthRoute path="/tabs" component={Tabs} />
          <Route path="/" exact render={handleOnboardingFlow} />
          <Route component={NotFound} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

const Tabs = () => {
  const [activeNotification] = React.useState(false);
  const { test } = useTestContext();

  if (!test) return <Redirect to="/" />;

  return (
    <IonTabs>
      <IonRouterOutlet animated={false}>
        <Route path="/tabs/inbox" exact component={InboxTab} />
        <Route path="/tabs/progress" exact component={ProgressTab} />
        <Route path="/tabs/products" exact component={ProductsTab} />
        <Route path="/tabs/info" exact component={InfoTab} />
        <Route
          path="/tabs"
          exact
          render={() => <Redirect to="/tabs/inbox" />}
        />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className={styles.tabBar}>
        <IonTabButton
          tab="inbox"
          href="/tabs/inbox"
          className={styles.tabButton}
        >
          <IonLabel
            className={cx({
              [styles.hasNotification]: activeNotification,
            })}
          >
            Inbox
          </IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="progress"
          href="/tabs/progress"
          className={styles.tabButton}
        >
          <IonLabel>Progress</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="products"
          href="/tabs/products"
          className={styles.tabButton}
        >
          <IonLabel>Products</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

const AuthRoute = (routeProps) => {
  const { user } = useFirebaseContext();
  if (!user) return <Redirect to="/login" />;

  return <Route {...routeProps} />;
};

// @ts-nocheck

import { IonModal } from "@ionic/react";
import React from "react";
import moment from "moment";
import styles from "./__styles__/ProgressModal.module.scss";
import EmojiInput from "./EmojiInput";

const ProgressModal = ({ report, index, ...modalProps }) => (
  <IonModal {...modalProps} cssClass={styles.modal}>
    <div className={styles.inner + " body-text"}>
      <div className={styles.header}>
        <span>
          {report.checkInKey === "before"
            ? "Pre-test Status"
            : `Check-in ${index || 0}`}
        </span>
        <span>{moment(report.completedAt.toDate()).format("MMM D, YYYY")}</span>
      </div>
      {report?.photo && (
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${report.photo})`,
            backgroundColor: "#333",
          }}
        ></div>
      )}
      {!report?.exam?.photo && (
        <div style={{ flex: 1, padding: 20, borderTop: "1px solid black" }}>
          <div className={styles.content}>
            {report.results.map((r) => (
              <>
                {r.type !== "emoji" && r.type !== "photo" && (
                  <div style={{ marginBottom: 36 }}>
                    <p style={{ fontWeight: "bold", marginBottom: 10 }}>
                      {r.text}
                    </p>

                    {r.type !== "emoji" && <p>{r.value}</p>}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}

      {report.emoji && (
        <div
          className={styles.section}
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <span
            className="body-text"
            style={{ lineHeight: "32px", fontWeight: 500 }}
          >
            Status:
          </span>{" "}
          <EmojiInput value={report.emoji} />
        </div>
      )}
    </div>
  </IonModal>
);

export default ProgressModal;

// const Product = ({ productRef }) => {
//   const [product, setProduct] = React.useState();

//   React.useEffect(() => {
//     productRef.get().then(setProduct);
//   }, []);

//   return (
//     <span className={styles.product}>
//       <span
//         className={styles.color}
//         style={{ background: product ? product.get("color") : "none" }}
//       ></span>{" "}
//       {product ? product.get("name") : ""}
//     </span>
//   );
// };

// @ts-nocheck

import React from "react";
import {
  IonButton,
  IonButtons,
  IonBackButton,
  IonContent,
  IonPage,
  IonToolbar,
} from "@ionic/react";

import { FirebaseContext } from "../../contexts/firebase";
import Footer from "../../components/Footer";
import styles from "../__styles__/ReviewFlow.module.scss";

export default ({ showNextProduct }) => {
  const { user } = React.useContext(FirebaseContext);

  return (
    <IonPage>
      <IonContent className={styles.content} fullscreen forceOverscroll={false}>
        <IonToolbar className={styles.header}>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/" icon="" color="light" />
          </IonButtons>
        </IonToolbar>
        <div className={styles.preview}>
          <h3>Your Reviews</h3>
          <p>
            Please leave a review of the following products that you tested.
          </p>
          {user.get("activeTest.products").map((ref) => (
            <ProductTitle key={ref.id} productRef={ref} />
          ))}
        </div>
      </IonContent>
      <Footer>
        <IonButton
          onClick={showNextProduct}
          color="secondary"
          expand="block"
          className={styles.button}
        >
          Get Started
        </IonButton>
      </Footer>
    </IonPage>
  );
};

const ProductTitle = ({ productRef }) => {
  const [product, setProduct] = React.useState();

  React.useEffect(() => {
    productRef.get().then(setProduct);
  }, []);

  return (
    <div className={styles.product}>
      <span
        className={styles.color}
        style={{ background: product?.get("color") || "none" }}
      ></span>{" "}
      {product?.get("name") || ""}
    </div>
  );
};

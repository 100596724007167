// @ts-nocheck

import React from "react";
import { useParams } from "react-router-dom";
import {
  IonButtons,
  IonBackButton,
  IonContent,
  IonPage,
  IonToolbar,
  useIonViewWillLeave,
  useIonViewWillEnter,
  NavContext,
} from "@ionic/react";

import { Plugins } from "@capacitor/core";
import { useReportsContext } from "../../contexts/reports";
import styles from "../__styles__/ReviewFlow.module.scss";
import Form from "./Form";
import { useExams } from "../../hooks/useExams";
import Header from "../../components/Header";

export default () => {
  useIonViewWillEnter(() => {
    Plugins.Keyboard.setResizeMode({ mode: "ionic" });
  });
  useIonViewWillLeave(() => {
    Plugins.Keyboard.setResizeMode({ mode: "none" });
  });

  const { navigate } = React.useContext(NavContext);
  let { initialCheckInKey, initialProductId } = useParams();
  const { create: createReport, incompleteReviewsReportProductIds } =
    useReportsContext();
  const { findByKey: findExamByKey } = useExams();

  const [checkInKey] = React.useState(initialCheckInKey);
  const [productId, setProductId] = React.useState(initialProductId);
  const exam = findExamByKey(checkInKey);

  if (
    checkInKey === "review" &&
    incompleteReviewsReportProductIds.length >= 1 &&
    !incompleteReviewsReportProductIds.includes(productId)
  ) {
    setProductId(incompleteReviewsReportProductIds[0]);
  }

  const onSubmitForm = async (formData) => {
    const overviewData = { createdAt: new Date() };

    const rating = formData?.results?.find((i) => i.type === "star")?.value;
    if (rating) overviewData.rating = rating;

    const emoji = formData?.results?.find((i) => i.type === "emoji")?.value;
    if (emoji) overviewData.emoji = emoji;

    const notes = formData?.results?.find((i) => i.type === "textarea")?.value;
    if (notes) overviewData.notes = notes;

    const photo = formData?.results?.find((i) => i.type === "photo")?.value;
    if (photo) overviewData.photo = photo;

    if (productId) overviewData.productIds = [productId];

    await createReport(exam, {
      ...formData,
      ...overviewData,
    });

    const incompleteProductId = incompleteReviewsReportProductIds
      .filter((p) => productId !== p)
      .pop();

    if (checkInKey === "review" && incompleteProductId) {
      setProductId(incompleteProductId);
      return;
    }

    if (checkInKey === "before") return navigate("/before-success");
    navigate("/tabs/inbox", "back");
  };

  return (
    <IonPage>
      <Header color="light" />

      {checkInKey !== "before" && (
        <IonToolbar
          className={styles.header}
          style={{ position: "absolute", top: -20, left: 20 }}
        >
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/" icon="" color="light" />
          </IonButtons>
        </IonToolbar>
      )}

      <IonContent className={styles.content} fullscreen scrollEvents={true}>
        <Form
          exam={exam}
          onSubmit={onSubmitForm}
          productId={productId}
          checkInKey={checkInKey}
        />
      </IonContent>
    </IonPage>
  );
};

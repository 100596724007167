import React, { useState } from "react";

import { IonButton, IonLoading } from "@ionic/react";
import { useCamera } from "@ionic/react-hooks/camera";
import { CameraResultType, CameraSource } from "@capacitor/core";
import { FirebaseContext } from "../contexts/firebase";

import styles from "./__styles__/StarInput.module.scss";

export default ({ value, onChange, disabled, readOnly = false }) => {
  const { storage } = React.useContext(FirebaseContext);
  const { getPhoto } = useCamera();
  const [isLoading, setLoading] = useState(false);

  const upload = async (dataUrl: string) => {
    let storageRef = storage.ref();
    const filename = Math.floor(Date.now() / 1000);
    const imgRef = storageRef.child(`images/${filename}.jpg`);
    const imgSnapshot = await imgRef.putString(dataUrl, "data_url");
    const imgUrl = await imgSnapshot.ref.getDownloadURL();
    setLoading(false);
    onChange(imgUrl);
  };

  const openCamera = async () => {
    try {
      const photo = await getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        quality: 95,
      });
      setLoading(true);
      upload(photo.dataUrl);
    } catch (err) {
      if (err.message && err.message.includes("denied access")) {
        const alert = document.createElement("ion-alert");
        let type = err.message.match(/denied access to (.+)/)[1];
        type = type[0].toUpperCase() + type.slice(1);

        alert.header = "Missing required permissions";
        alert.message = `
            System Preferences > Privacy > ${type}
          
            Make sure Body Project is toggled to "ON"`;
        alert.buttons = ["OK"];

        document.body.appendChild(alert);
        return alert.present();
      }
      return;
    }
  };
  return (
    <div className={styles.container}>
      <div
        className={styles.button}
        style={{
          position: "relative",
          marginTop: 20,
          width: 190,
          height: 190,
          padding: 30,
          backgroundColor: "black",
          borderRadius: 10,
          backgroundImage: value ? `url(${value})` : ``,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {!value && !isLoading && (
          <IonButton
            onClick={openCamera}
            color="secondary"
            expand="block"
            className={styles.button}
          >
            Open Camera
          </IonButton>
        )}

        {isLoading && (
          <IonLoading isOpen={true} message="Preparing image for upload" />
        )}

        {value && !isLoading && (
          <div
            role="button"
            style={{
              position: "absolute",
              right: 12,
              top: 12,
              background: "black",
              padding: 10,
              borderRadius: 5,
            }}
            onClick={() => {
              onChange(null);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <g fill="#d33">
                <path d="M17 2h-3.5l-1-1h-5l-1 1H3v2h14zM4 17a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5H4z" />
              </g>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

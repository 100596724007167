import React from "react";

import {
  IonSlides,
  IonSlide,
  IonContent,
  IonPage,
  IonButton,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProgressBar from "../components/ProgressBar";
import styles from "./__styles__/Demo.module.scss";
import { useTestContext } from "../contexts/test";

export default () => {
  const [endReached, setEndReached] = React.useState(false);
  const { test, ref: testRef } = useTestContext();
  const [progress, setProgress] = React.useState(0.1);

  useIonViewDidEnter(() => {
    window.addEventListener("ionSlideReachEnd", handleEnd);
    window.addEventListener("ionSlideDidChange", handleProgress);
  });

  useIonViewWillLeave(() => {
    window.removeEventListener("ionSlideReachEnd", handleEnd);
    window.removeEventListener("ionSlideDidChange", handleProgress);
  });

  const handleEnd = () => {
    setEndReached(true);
  };

  const handleProgress = (evt) => {
    evt.target.getActiveIndex().then((idx) => {
      setProgress(idx / test?.trial?.onboarding?.screens.length);
    });
  };

  const handleSubmit = async () => {
    await testRef.update({
      demoSeen: new Date(),
    });
  };

  return (
    <IonPage>
      <Header />
      <IonContent forceOverscroll={false} fullscreen>
        <IonSlides pager={true} class={styles.container}>
          {(test?.trial?.onboarding?.screens || []).map((s) => (
            <IonSlide key={s.message} class={styles.slide}>
              <div className={styles.icon}>
                <span role="img" aria-label="Step Icon">
                  {s.icon}
                </span>
              </div>
              <div className={styles.text}>
                <p className="body-text">{s.message}</p>
              </div>
            </IonSlide>
          ))}
        </IonSlides>
      </IonContent>
      <Footer>
        <IonButton
          disabled={!endReached}
          onClick={handleSubmit}
          expand="block"
          class={styles.button}
        >
          Got it!
        </IonButton>
        <ProgressBar value={progress} show={true} />
      </Footer>
    </IonPage>
  );
};

import { useTestContext } from "../contexts/test";
import { useReportsContext } from "../contexts/reports";

export const useExams = () => {
  const { test } = useTestContext();
  const { isExamComplete } = useReportsContext();

  const findByKey = (key) => {
    return test?.exams.find((c: any) => c.checkInKey === key);
  };

  const exams = test?.exams || [];

  const beforeExam = exams.find((c) => c.checkInKey === "before");

  const progressExams = exams
    .filter((r: any) => r.checkInKey !== "review")
    .filter((r: any) => !!r.tasksRequired)
    .sort((a: any, b: any) => a.order - b.order);

  const pastExams = progressExams.filter((e: any) => {
    return new Date().getTime() - e?.startDate?.toDate().getTime() > 0;
  });

  const mostRecentPastExam = pastExams[pastExams.length - 1];

  const activeExam = !isExamComplete(mostRecentPastExam)
    ? mostRecentPastExam
    : null;

  const lastCompleteExam = progressExams
    .filter((c: any) => {
      return isExamComplete(c);
    })
    .pop();

  const missedExams = pastExams.filter((e: any) => {
    if (activeExam === e) return false;
    return !isExamComplete(e);
  });

  const futureExams = progressExams.filter((e: any) => {
    if (!e.startDate && !isExamComplete(e)) return true;
    return new Date().getTime() - e?.startDate?.toDate().getTime() < 0;
  });

  const upcomingExam = !!test?.started && !activeExam && futureExams[0];

  return {
    exams,
    progressExams,
    beforeExam,
    activeExam,
    lastCompleteExam,
    missedExams,
    futureExams,
    upcomingExam,
    findByKey,
  };
};

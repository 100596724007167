import React from "react";
import cx from "classnames";

import { IonProgressBar } from "@ionic/react";
import styles from "./__styles__/ProgressBar.module.scss";

export default ({ show, ...props }) => {
  return (
    <IonProgressBar
      color="secondary"
      {...props}
      className={cx(styles.bar, {
        [styles.show]: show,
      })}
    />
  );
};

import * as firebase from "firebase/app";
import React from "react";
import { useEffect, createContext, useState, useContext } from "react";

import { useFirebaseContext } from "./firebase";
import { useTestContext } from "./test";

const ReportsContext = createContext({
  reports: null,
  loaded: false,
  reportsRef: null,
  isExamComplete: null,
  getReportByExam: null,
  create: null,
  isReviewReportComplete: null,
  completeReviewReportProductIds: [],
  incompleteReviewsReportProductIds: [],
});

const ReportsContextProvider = ({ children }) => {
  const { test, ref: testRef, log } = useTestContext();
  const { db, user } = useFirebaseContext();
  const [reports, setReports] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const reportsRef = db.collection("reports");

  useEffect(() => {
    if (!test || !user || !user.get("enrolledTestId")) return;

    const ref = reportsRef.where("testId", "==", user.get("enrolledTestId"));

    const unsubscribe = ref.onSnapshot((snapshot) => {
      setReports(
        [...snapshot.docs]
          .map((doc) => {
            return { ...doc.data(), id: doc.id, ref: doc.ref };
          })
          .sort((a, b) => a.order - b.order)
      );
      setLoaded(true);
    });

    return () => {
      setLoaded(false);
      unsubscribe();
    };
  }, [test, setLoaded, user, setReports]);

  const isExamComplete = (exam) => {
    if (!exam) return false;

    return !!reports.find((r) => r.checkInKey === exam.checkInKey);
  };

  const getReportByExam = (exam) => {
    return reports.find((r) => r.checkInKey === exam.checkInKey);
  };

  const isReviewReportComplete = !!reports.find(
    (r) => r.checkInKey === "review"
  );

  const getCompleteReviewReportProductIds = () => {
    const productIds = [];
    reports.forEach((r) => {
      if (r.checkInKey !== "review") return;
      productIds.push(...(r.productIds || []));
    });
    return productIds;
  };

  const completeReviewReportProductIds = getCompleteReviewReportProductIds();

  const getIncompleteProductReviews = () => {
    const productIds = [];
    const completeIds = completeReviewReportProductIds;
    test?.productIds?.forEach((id) => {
      if (!completeIds.includes(id)) productIds.push(id);
    });

    return productIds;
  };

  const incompleteReviewsReportProductIds = getIncompleteProductReviews();

  const create = async (exam, data) => {
    await reportsRef.add({
      testId: test.id,
      userId: user.id,
      checkInKey: exam.checkInKey,
      examId: exam.id,
      exam,
      completedAt: new Date(),
      trialCode: test.trial.code,
      trialTitle: test.trial.title,
      productIds: test.productIds || [],
      ...data,
    });

    testRef.update({
      completedExams: firebase.firestore.FieldValue.arrayUnion(exam.id),
    });

    log(`Completed exam '${exam.checkInKey}' (${exam.id}) `);
  };

  return (
    <ReportsContext.Provider
      value={{
        reports,
        loaded,
        reportsRef,
        create,
        isExamComplete,
        getReportByExam,
        isReviewReportComplete,
        completeReviewReportProductIds,
        incompleteReviewsReportProductIds,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export { ReportsContextProvider };
export const useReportsContext = () => useContext(ReportsContext);

import React from "react";
import { IonHeader, IonTitle, IonToolbar, IonButton } from "@ionic/react";
import cx from "classnames";
import styles from "./__styles__/Header.module.scss";

export default ({ color = "default", link = false }) => (
  <IonHeader className="ion-no-border">
    <IonToolbar className={styles.header}>
      <IonTitle class="ion-text-center">
        <IonButton
          className={cx(styles.title, {
            [styles.light]: color === "light",
            [styles.red]: color === "red",
          })}
          routerLink={link ? "/tabs/info" : "/"}
          routerDirection="root"
          fill="clear"
        >
          Body Project
        </IonButton>
      </IonTitle>
    </IonToolbar>
  </IonHeader>
);

import React from "react";

import {
  IonContent,
  IonPage,
  IonButton,
  IonInput,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonModal,
  IonToast,
  IonLoading,
} from "@ionic/react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "./__styles__/Setup.module.scss";
import ProgressBar from "../components/ProgressBar";
import { useTrial } from "../hooks/useTrial";

export default () => {
  const { enrollUserInTrial } = useTrial();

  const [code, setCode] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await enrollUserInTrial(code, firstName, lastName);
    } catch (e) {
      console.error(e);
      setError("We couldn't locate your account info.");
    }

    setLoading(false);
  };

  const handleTermsClick = (evt: any) => {
    evt.preventDefault();
    setShowModal(true);
  };

  return (
    <IonPage>
      <Header />
      <IonToast
        isOpen={!!error}
        onDidDismiss={() => setError("")}
        message={error}
        duration={700}
        header="Error"
        color="danger"
        position="top"
      />
      <IonContent class="ion-padding" fullscreen forceOverscroll={false}>
        <div className={styles.form}>
          <h3 className="ion-text-center">
            Please enter the unique <br /> passphrase sent to your email:
          </h3>
          <IonInput
            value={code}
            className={styles.input}
            style={{ marginBottom: "6vh" }}
            placeholder="passphrase"
            onIonChange={(e: any) => setCode(e.target.value)}
          />
          <h4 className="ion-text-center">
            Please enter your preferred <br />
            first and last name:
          </h4>
          <IonInput
            value={firstName}
            className={styles.input}
            style={{ marginBottom: 16 }}
            placeholder="first name"
            onIonChange={(e: any) => setFirstName(e.target.value)}
          />
          <IonInput
            value={lastName}
            className={styles.input}
            placeholder="last name"
            onIonChange={(e: any) => setLastName(e.target.value)}
          />
        </div>
      </IonContent>
      <Footer>
        <div className={styles.terms}>
          <IonItem lines="none" class={styles.checkbox}>
            <IonCheckbox
              checked={termsAccepted}
              onIonChange={(e) => setTermsAccepted(e.detail.checked)}
            />
            <IonLabel className="body-text" style={{ marginLeft: 6 }}>
              I agree to the{" "}
              <span className={styles.link} onClick={handleTermsClick}>
                Terms &amp; Conditions
              </span>
            </IonLabel>
          </IonItem>
        </div>
        <IonButton
          disabled={!code || !termsAccepted}
          onClick={handleSubmit}
          expand="block"
          class={styles.button}
        >
          Join Trial
        </IonButton>
        <ProgressBar value={0.3} show={true} />
      </Footer>
      <IonLoading spinner="crescent" isOpen={loading} />
      <IonModal isOpen={showModal}>
        <IonContent>
          <span
            onClick={() => {
              setShowModal(false);
            }}
            className={styles.close}
          >
            Close
          </span>
          <div className={styles.modalContent}>
            <header>
              <h2>Terms &amp; Conditions</h2>
              <p>Product Tester Agreement</p>
            </header>

            <p>
              I, the individual using this app, have agreed to test and create
              and post reviews (the “Reviews”) of certain products (the
              “Products”) provided to me by A to Z International Corporation, a
              Delaware corporation (“A to Z”).
            </p>

            <p>
              Waiver and Release. <br />I understand that the Products may
              present risks, including without limitation bodily injury,
              allergic reactions, and property damage, and that A to Z depends
              on me to use my good judgment and reasonable care for my own
              safety and the safety of others while using the Products. In
              consideration of my receipt of the Products and other mutually
              agreed consideration provided to me by A to Z (the receipt and
              sufficiency of which is hereby acknowledged) and by signing this
              Agreement, I acknowledge, agree, and understand that my
              participation is voluntary and that I accept and knowingly assume
              all risks associated with the Products. I further agree to
              expressly waive and release A to Z and any of their directors,
              officers, employees, contractors, agents, affiliates, and parent
              and/or subsidiary entities (the “Released Parties”), from and
              against any and all claims, actions, damages, losses, liabilities,
              costs, expenses, injuries or causes of action whatsoever
              (including, but not limited to, reasonable outside attorneys’ fees
              and court costs, whether or not in connection with litigation),
              now known or hereafter known, arising out of or attributable to my
              use or Reviews of the Products. I covenant not to make or bring
              any such claim against the Released Parties, and forever release
              and discharge the Released Parties from liability under such
              claims. This Agreement specifically includes any claims made by me
              arising from the negligent acts of the Released Parties.
            </p>

            <p>
              Use of Materials.
              <br />
              Additionally, I hereby grant A to Z and its subsidiaries,
              affiliates, parent, licensees and assigns an irrevocable,
              royalty-free right and license to use, adapt, reproduce,
              distribute, exhibit, display, perform and exploit any images or
              photographs that I provide in connection with the Reviews or the
              Products (the “Materials”) throughout the world in any media now
              known or hereafter devised, in perpetuity, in any advertising,
              promotional, publicity and other materials related to the
              Products, including, but not limited to on A to Z’s websites,
              social media accounts, blogs and other online media. I expressly
              release A to Z from and against any and all claims which I have or
              may have for invasion of privacy, right of publicity, defamation,
              copyright infringement, or any other causes of action arising out
              of the use, adaptation, reproduction, distribution, display,
              broadcast, performance, or exhibition of the Materials as
              permitted in this Agreement.
            </p>

            <p>
              Product Reviews. <br />I agree that the Reviews will consist of my
              honest opinions concerning the Products. I further acknowledge and
              agree that any Review must include a clear and conspicuous
              disclosure suited to the online property on which the Reviews are
              published that: (a) complies with all applicable laws, including
              the Federal Trade Commission’s then current Guides Concerning the
              Use of Endorsements and Testimonials in Advertising, (b)
              conspicuously discloses any personal or professional connection
              between me and A to Z, and (c) discloses that I was provided a
              financial incentive by A to Z to test the Products and provide the
              Review.
            </p>

            <p>
              Miscellaneous. <br /> If any term or provision of this Agreement
              is invalid, illegal, or unenforceable in any jurisdiction, the
              invalidity, illegality, or unenforceability will not affect any
              other term or provision of this Agreement or invalidate or render
              unenforceable such term or provision in any other jurisdiction.
              All matters arising out of or relating to this Agreement will be
              governed by and construed in accordance with the laws of the State
              of New York. Any claim or cause of action arising under this
              Agreement may be brought only in the federal and state courts
              located in New York, New York and I hereby consent to the
              exclusive jurisdiction of such courts.
            </p>

            <p>
              By checking below, I acknowledge that I have read and understood
              all of the terms of this Agreement, that I am voluntarily giving
              up substantial legal rights, including the right to sue A to Z,
              and that I have the full and exclusive right and authority to
              grant the rights granted in this Agreement.
            </p>
          </div>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

import React from "react";
import { IonContent, IonPage, IonButton } from "@ionic/react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "./__styles__/BeforeSuccess.module.scss";
import { useTestContext } from "../contexts/test";

export default () => {
  const { test } = useTestContext();

  return (
    <IonPage>
      <Header color="light" />
      <IonContent fullscreen forceOverscroll={false} className={styles.content}>
        <div className={styles.container}>
          <span className={styles.icon} role="img" aria-label="sparkle">
            ✨
          </span>
          <p>
            {test?.trial?.onboarding?.beforeSuccessMessage ||
              `You’re all done for today. Look out for your sample to arrive by mail within 3-5 business days.`}
          </p>
        </div>
      </IonContent>
      <Footer>
        <IonButton
          color="light"
          href="/tabs"
          className={styles.link}
          fill="clear"
        >
          View Your Profile
        </IonButton>
      </Footer>
    </IonPage>
  );
};

// @ts-nocheck

import {
  IonButton,
  IonContent,
  IonInput,
  IonPage,
  IonLoading,
  IonToast,
  useIonViewDidEnter,
  useIonViewWillLeave,
  useIonViewWillEnter,
  //isPlatform,
} from "@ionic/react";

import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";

import { cfaSignIn, cfaSignInPhoneOnCodeSent } from "capacitor-firebase-auth";
import styles from "./__styles__/Login.module.scss";

import PhoneInput from "react-phone-number-input";
import Header from "../components/Header";
import ProgressBar from "../components/ProgressBar";
import Footer from "../components/Footer";

import "react-phone-number-input/style.css";
import { Plugins } from "@capacitor/core";

export default () => {
  const [phone, setPhone] = React.useState("");

  const [code, setCode] = React.useState("");
  const [screen, setScreen] = React.useState("init");
  const [verifier, setVerifier] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  //const isDesktop = isPlatform("desktop");

  const onPhoneSubmit = () => {
    setLoading(true);

    // This is an account for apple corp tester
    if (phone.includes("555555")) {
      console.log("Logging in with tester acount:", phone);
      const credential = firebase.auth.EmailAuthProvider.credential(
        `bp-${phone.slice(-4)}@0is1.com`,
        "tb1890"
      );

      firebase
        .auth()
        .signInWithCredential(credential)
        .catch((err) => {
          console.log(err);
        });

      return;
    }

    cfaSignIn("phone", { phone }).subscribe(
      () => {
        setLoading(false);
      },
      (err) => {
        console.log("cfaSignIn:", err);
        setError(
          `Oops. We've encountered an error. Please wait a few minutes and try again!`
        );
        setLoading(false);
      },
      () => {
        setScreen("code");
        setLoading(false);
      }
    );
  };

  const onCodeSubmit = () => {
    setLoading(true);
    const credential = firebase.auth.PhoneAuthProvider.credential(
      verifier,
      code
    );

    firebase
      .auth()
      .signInWithCredential(credential)
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setError(
          "Oops! Coudldn't sign you in with the provided code. Please try again."
        );
      });
  };

  const onInit = () => {
    setScreen("login");
  };

  const onReset = () => {
    setScreen("login");
  };

  let sub;

  useIonViewWillEnter(() => {
    Plugins.Keyboard.setResizeMode({ mode: "none" });
  });

  useIonViewDidEnter(() => {
    sub = cfaSignInPhoneOnCodeSent().subscribe(
      (verificationId) => {
        setVerifier(verificationId);
        setLoading(false);
        setScreen("code");
      },
      (err) => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  });

  useIonViewWillLeave(() => {
    sub.unsubscribe();
  });

  const renderForm = () => {
    switch (screen) {
      case "init":
        return (
          <div className={styles.intro}>
            <h1>Welcome!</h1>
            <p className="body-text">To start, let's set up your account.</p>
          </div>
        );
      case "login":
        return (
          <form className={styles.form}>
            <h2 className="ion-text-center">
              Please enter your <br /> phone number:
            </h2>
            <PhoneInput
              value={phone}
              onChange={setPhone}
              type="tel"
              defaultCountry="US"
            />
          </form>
        );

      case "code":
        return (
          <div className={styles.form}>
            <h2
              className="ion-text-center"
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              You will recieve a text message with a 6-digit code. Enter it
              here:
            </h2>

            <IonInput
              value={code}
              className={styles.input}
              inputmode="numeric"
              type="number"
              placeholder="000000"
              onIonChange={(e: any) => setCode(e.target.value)}
            />
          </div>
        );

      default:
        return null;
    }
  };

  const renderFooter = () => {
    switch (screen) {
      case "init":
        return (
          <>
            <IonButton
              fill="clear"
              onClick={() => onInit(true)}
              expand="block"
              className="body-text"
              style={{ textDecoration: "underline" }}
            >
              Already have an account? Login
            </IonButton>
            <IonButton
              onClick={() => onInit(false)}
              expand="block"
              class={styles.button}
            >
              Start Here
            </IonButton>
          </>
        );
      case "login":
        return (
          <>
            <p className={styles.security}>
              We use phone number login for <br />
              accounts as it is the most secure.
            </p>
            <IonButton
              disabled={!phone}
              onClick={onPhoneSubmit}
              expand="block"
              id="phone-submit"
              class={styles.button}
            >
              Submit
            </IonButton>
          </>
        );
      case "code":
        return (
          <div>
            <IonButton
              onClick={onReset}
              fill="clear"
              expand="block"
              className="body-text"
              style={{ textDecoration: "underline" }}
            >
              Didn’t get a code? Have us resend it.
            </IonButton>
            <IonButton
              onClick={onCodeSubmit}
              expand="block"
              class={styles.button}
            >
              Confirm
            </IonButton>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <IonPage>
      <Header />

      <IonContent
        fullscreen
        className={styles.content}
        forceOverscroll={false}
        style={{ "--background": "#F8F8F5" }}
      >
        {renderForm()}
      </IonContent>

      <Footer>
        {renderFooter()}
        <ProgressBar value={screen === "init" ? 0.1 : 0.2} show={true} />
      </Footer>

      <IonLoading spinner="crescent" isOpen={loading} />
      <IonToast
        isOpen={!!error}
        onDidDismiss={() => setError("")}
        message={error}
        duration={1500}
        header="Error"
        color="danger"
        position="top"
      />
    </IonPage>
  );
};

// @ts-nocheck

import React from "react";
import {
  IonButton,
  IonLabel,
  IonTextarea,
  NavContext,
  IonLoading,
  IonToast,
  IonRadioGroup,
  IonItem,
  IonRadio,
} from "@ionic/react";

import { FirebaseContext } from "../../contexts/firebase";

import StarInput from "../../components/StarInput";
import styles from "../__styles__/ReviewFlow.module.scss";
import useForm, { IS_REQUIRED } from "../../hooks/forms";
import ProductCard from "./ProductCard";

export default ({ flowIndex, product, productRef, showNextProduct }) => {
  const { navigate } = React.useContext(NavContext);
  const { user, notifications } = React.useContext(FirebaseContext);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const quiz = product?.get("reviewQuiz") || DEFAULT_FORM;
  const notice = product?.get("reviewNotice");
  const { get, set, validate, errors } = useForm(formToFormRules(quiz));

  const formatReview = () => {
    return quiz.reduce((accum, value) => {
      accum.push({
        question: value.label,
        answer: get(value.formField),
      });

      return accum;
    }, []);
  };

  const submitReview = async () => {
    setLoading(true);
    try {
      validate();
    } catch (err) {
      setError("Please answer all questions.");
      setLoading(false);
      return;
    }

    await user.ref.collection("reviews").add({
      rating: get(quiz.find((i) => i.type === "stars")?.formField) || 0,
      form: formatReview(),
      createdAt: new Date(),
      testId: user.get("activeTest.id"),
      product: productRef,
      user: {
        id: user.id,
        name: user.get("name"),
        phone: user.get("phoneNumber"),
      },
    });

    if (flowIndex < user.get("activeTest.products").length) {
      showNextProduct();
      setLoading(false);
    } else {
      const notification = notifications.find(
        (n) => n.get("type") === "review"
      );
      if (notification) {
        await notification.ref.update("active", false);
      }

      navigate("/tabs/inbox", "back");
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.review}>
        {notice && <p className={styles.notice}>{notice}</p>}
        <ProductCard
          product={product}
          total={user.get("activeTest.products").length}
          count={flowIndex}
        />
        <div className={styles.form}>
          {(quiz || []).map((field) => (
            <div
              className={styles.fieldset}
              key={`${flowIndex}-${field.formField}`}
            >
              <IonLabel className={styles.label}>
                {field.label}{" "}
                {errors[field.formField] && (
                  <span className={styles.error}>Required</span>
                )}
              </IonLabel>

              {(field.type === "textarea" || field.type === "text") && (
                <IonTextarea
                  inputmode="text"
                  placeholder={`Your answer here. ${
                    field.required ? "" : "(Optional)"
                  }`}
                  className={styles.input}
                  autoGrow={true}
                  autocapitalize
                  rows={field.required ? 9 : 2}
                  value={get(field.formField)}
                  onIonChange={(evt) => set(field.formField)(evt.target.value)}
                  disabled={loading}
                ></IonTextarea>
              )}

              {field.type === "stars" && (
                <StarInput
                  onChange={set(field.formField)}
                  value={get(field.formField)}
                  disabled={loading}
                />
              )}

              {field.type === "bool" && (
                <IonRadioGroup
                  onIonChange={(e) => set(field.formField)(e.detail.value)}
                >
                  <IonItem>
                    <IonLabel>Yes</IonLabel>
                    <IonRadio slot="start" value="yes" />
                  </IonItem>

                  <IonItem>
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="no" />
                  </IonItem>
                </IonRadioGroup>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.footer}>
        <IonButton
          onClick={submitReview}
          color="secondary"
          expand="block"
          disabled={loading}
          className={styles.button}
        >
          Save Review
        </IonButton>
      </div>
      <IonToast
        isOpen={!!error}
        onDidDismiss={() => setError("")}
        message={error}
        duration={700}
        header="Error"
        color="danger"
        position="top"
      />
      <IonLoading spinner="crescent" isOpen={loading} />
    </>
  );
};

const DEFAULT_FORM = [
  {
    label: "1. General review",
    formField: "general",
    type: "textarea",
    required: true,
  },
  {
    label: "2. Overall, how would you rate this product?",
    formField: "rating",
    type: "stars",
    required: true,
  },
  {
    label: "3. Who would you recommend use this product?",
    formField: "recommend",
    type: "textarea",
  },
  {
    label: "4. Do you have suggestions on how to improve this product?",
    formField: "suggestions",
    type: "textarea",
  },
  {
    label: "5. Additional notes?",
    formField: "notes",
    type: "textarea",
  },
];

const formToFormRules = (form) => {
  return form.map((i) => {
    return {
      name: i.formField,
      value: i.type === "stars" ? 0 : "",
      validation:
        i.required === true
          ? [
              {
                validator: IS_REQUIRED,
                message: "Required field",
              },
            ]
          : [],
    };
  });
};

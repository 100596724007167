import React from "react";
import cx from "classnames";

import styles from "./__styles__/StarInput.module.scss";

const emojis = ["😃", "🙂", "😐", "😣", "😭"];

export default ({ value, onChange, disabled, readOnly = false }) => {
  const onClick = (value) => () => {
    if (disabled || readOnly) return;
    onChange(value);
  };

  return (
    <div className={styles.container}>
      {[1, 2, 3, 4, 5].map((rating) => (
        <span
          role="img"
          aria-label={`rating ${rating}`}
          key={rating}
          onClick={onClick(rating)}
          className={cx(styles.icon, {
            [styles.selected]: rating === value,
            [styles.disabled]: disabled,
          })}
        >
          {emojis[rating - 1]}
        </span>
      ))}
    </div>
  );
};

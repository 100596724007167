import { useFirebaseContext } from "../contexts/firebase";
export const useTrial = () => {
  const { user, db, log } = useFirebaseContext();

  const enrollUserInTrial = async (trialCode, firstName, lastName) => {
    const trials = await db
      .collection("trials")
      .where("code", "==", trialCode.toLowerCase())
      .where("deleted", "==", false)
      .limit(1)
      .get();

    if (trials.empty) {
      console.log("Couldn't find trial code.");
      throw new Error("");
    }

    const trial = {
      id: trials.docs[0].id,
      ...trials.docs[0].data(),
    };

    const exams = Object.keys(trial?.checkIns || [])
      .filter((key) => trial.checkIns[key])
      .map((key) => {
        const checkIn = trial.checkIns[key];

        // This fixes a glitch caused by react admin
        if (checkIn?.tasks) {
          checkIn.tasks = checkIn?.tasks.filter(function (e) {
            return e;
          });
        }

        return {
          id: Math.random().toString(36).replace("0.", ""),
          tasksRequired: checkIn?.tasks?.length > 0,
          checkInKey: key,
          deleted: false,
          daysDueFromStarted:
            Number(key.replace("day-", "")) || (key === "before" ? 0 : 99999),
          ...checkIn,
        };
      })
      .sort((a, b) => a.daysDueFromStarted - b.daysDueFromStarted)
      .map((r, i) => {
        return { ...r, order: i };
      });

    if (exams[exams.length - 1].checkInKey === "review") {
      exams[exams.length - 1].daysDueFromStarted =
        exams[exams.length - 2].daysDueFromStarted + 1;
    }

    let test = {
      trial,
      code: trial?.code,
      title: trial?.title || "unnamed",
      productIds: trial?.productIds || ["unspecified"],
      exams,
      createdAt: new Date(),
      userId: user.id,
      deleted: false,
      closed: false,
    };

    const testRef = await db.collection("tests").add(test);
    const testObj = { ...test, id: testRef.id, exams };

    log(
      `User ${user.id} has signed up for trial '${trial?.code.toLowerCase()}'`,
      "testsignup"
    );

    user.ref.set(
      { enrolledTestId: testObj.id, firstName, lastName },
      { merge: true }
    );

    return testObj;
  };

  return { enrollUserInTrial };
};

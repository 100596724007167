import React from "react";
import { useEffect, createContext, useState, useContext } from "react";
import { useFirebaseContext } from "./firebase";
import * as firebase from "firebase/app";

const TestContext = createContext({
  test: null,
  ref: null,
  loaded: false,
  start: null,
  log: null,
});

const TestContextProvider = ({ children }) => {
  const { user, db } = useFirebaseContext();
  const [test, setTest] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const userData = user?.data();
  const ref = db
    .collection("tests")
    .doc(userData?.enrolledTestId || "PATH_UNDEFINED");

  useEffect(() => {
    if (!userData?.enrolledTestId) return;

    const unsubscribe = ref.onSnapshot((snapshot) => {
      if (
        snapshot?.get("closed") ||
        snapshot?.get("deleted") ||
        snapshot.exists === false
      ) {
        user.ref.set(
          { enrolledTestId: firebase.firestore.FieldValue.delete() },
          { merge: true }
        );
        setLoaded(true);
        return null;
      }
      setLoaded(true);
      setTest({ ...snapshot.data(), id: snapshot.id });
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  const start = async () => {
    const exams = [...test.exams].map((c) => {
      return {
        ...c,
        startDate: addDays(
          new Date(new Date().setHours(0, 0, 0, 0)),
          c.daysDueFromStarted
        ),
      };
    });

    log("Product recieved. Testing period has begun.");

    return ref.set({ started: new Date(), exams }, { merge: true });
  };

  const log = async (message) => {
    ref.collection("logs").add({ message, createdAt: new Date() });
  };

  return (
    <TestContext.Provider
      value={{
        loaded,
        test,
        start,
        ref,
        log,
      }}
    >
      {children}
    </TestContext.Provider>
  );
};

export { TestContextProvider };
export const useTestContext = () => useContext(TestContext);

const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

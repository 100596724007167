import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { init, FirebaseContextProvider } from "./contexts/firebase";
import { TestContextProvider } from "./contexts/test";
import { ReportsContextProvider } from "./contexts/reports";
import { setupConfig } from "@ionic/react";

init();

setupConfig({
  navAnimation: null,
});

ReactDOM.render(
  <FirebaseContextProvider>
    <TestContextProvider>
      <ReportsContextProvider>
        <App />
      </ReportsContextProvider>
    </TestContextProvider>
  </FirebaseContextProvider>,
  document.getElementById("root")
);

import React from "react";

import { IonButton, IonContent, IonPage } from "@ionic/react";
import Header from "../components/Header";

import styles from "./__styles__/NotFound.module.scss";

export default () => {
  return (
    <IonPage>
      <Header link />
      <IonContent className={styles.content} fullscreen>
        <div className={styles.container}>
          <h2>Oh snap!</h2>
          <p>Something ain't right.</p>
          <div className={styles.buttons}>
            <IonButton className={styles.button} href="/">
              Go Back!
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

// @ts-nocheck

import React from "react";
import {
  IonButtons,
  IonBackButton,
  IonContent,
  IonPage,
  IonToolbar,
  useIonViewWillLeave,
  useIonViewWillEnter,
} from "@ionic/react";

import { Plugins } from "@capacitor/core";
import { FirebaseContext } from "../../contexts/firebase";
import styles from "../__styles__/ReviewFlow.module.scss";
import ReviewProductOverview from "./ReviewProductOverview";
import ReviewForm from "./ReviewForm";

export default () => {
  const contentRef = React.useRef();
  const { user } = React.useContext(FirebaseContext);
  const [flowIndex, setFlowIndex] = React.useState(0);
  const [currentProductRef, setCurrentProductRef] = React.useState();

  const [product, setProduct] = React.useState();

  useIonViewWillEnter(() => {
    Plugins.Keyboard.setResizeMode({ mode: "ionic" });
  });
  useIonViewWillLeave(() => {
    Plugins.Keyboard.setResizeMode({ mode: "none" });
  });

  React.useEffect(() => {
    if (!flowIndex) {
      setCurrentProductRef(null);
      return;
    }

    setCurrentProductRef(user.get("activeTest.products")[flowIndex - 1]);

    if (contentRef && contentRef.current) {
      contentRef.current.scrollToTop();
    }
  }, [flowIndex]);

  React.useEffect(() => {
    if (!currentProductRef) return;
    currentProductRef.get().then(setProduct);
  }, [currentProductRef]);

  const showNextProduct = () => {
    setCurrentProductRef(null);
    setProduct(null);
    setFlowIndex((state) => {
      return state + 1;
    });
  };

  if (!currentProductRef) {
    return <ReviewProductOverview showNextProduct={showNextProduct} />;
  }

  return (
    <IonPage>
      <IonContent
        className={styles.content}
        fullscreen
        scrollEvents={true}
        ref={contentRef}
      >
        <IonToolbar className={styles.header}>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/" icon="" color="light" />
          </IonButtons>
        </IonToolbar>
        {product && (
          <ReviewForm
            product={product}
            productRef={currentProductRef}
            showNextProduct={showNextProduct}
            flowIndex={flowIndex}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

// @ts-nocheck

import React from "react";

import {
  IonButton,
  IonContent,
  IonPage,
  IonLoading,
  NavContext,
} from "@ionic/react";

import Header from "../components/Header";
import styles from "./__styles__/Inbox.module.scss";
import { useTestContext } from "../contexts/test";
import { useReportsContext } from "../contexts/reports";
import { useExams } from "../hooks/useExams";

export default () => {
  const { test, start } = useTestContext();
  const { isExamComplete, isReviewReportComplete } = useReportsContext();
  const { activeExam, progressExams } = useExams();

  const [loading, setLoading] = React.useState(false);
  const { navigate } = React.useContext(NavContext);
  const handeProductsReceived = async () => {
    setLoading(true);
    await start();

    setLoading(false);
    navigate("/tabs/products", "forward");
  };

  const renderNotification = () => {
    const isLastCheckinComplete =
      progressExams &&
      isExamComplete(progressExams?.[progressExams.length - 1]);

    if (!test.started)
      return (
        <AwaitingProductsNotification onComplete={handeProductsReceived} />
      );

    if (activeExam) return <ProgressNotification activeExam={activeExam} />;

    if (isLastCheckinComplete && !isReviewReportComplete)
      return <ReviewNotification />;

    if (isLastCheckinComplete && isReviewReportComplete)
      return <FinalNotification />;

    return <SuccessNotification />;
  };

  return (
    <IonPage>
      <Header link />
      <IonContent fullscreen forceOverscroll={false} className={styles.content}>
        <div className={styles.container}>{renderNotification()}</div>
      </IonContent>

      <IonLoading isOpen={loading} />
    </IonPage>
  );
};

const AwaitingProductsNotification = ({ onComplete }) => {
  return (
    <>
      <span role="img" aria-label="box" className={styles.icon}>
        📦
      </span>
      <p className="body-text">We’re shipping a package to you.</p>

      <p className="body-text">
        Once received, come back here <br /> to continue.
      </p>
      <IonButton
        color="secondary"
        className={styles.button}
        expand="block"
        onClick={onComplete}
      >
        I got my package
      </IonButton>
    </>
  );
};

const ReviewNotification = () => {
  return (
    <>
      <div className={styles.header}>
        <span role="img" aria-label="trophy" className={styles.icon}>
          🏆
        </span>
        <h5>Testing is complete.</h5>
      </div>
      <p className="body-text">
        The last thing left to do is leave a review of the products.
      </p>
      <IonButton
        color="secondary"
        className={styles.button}
        expand="block"
        routerLink="/add-check-in/review"
      >
        Review Products
      </IonButton>
    </>
  );
};

const ProgressNotification = ({ activeExam }) => {
  return (
    <>
      <div className={styles.header}>
        <h5>New Progress Update!</h5>
        <span role="img" aria-label="box" className={styles.icon}>
          📈
        </span>
      </div>
      <p className="body-text">
        We hope you&rsquo;ve been enjoying the products. Time to add an update.
      </p>

      <IonButton
        color="secondary"
        className={styles.button}
        expand="block"
        routerLink={`/add-check-in/${activeExam?.checkInKey}`}
      >
        Add Update
      </IonButton>
    </>
  );
};

const SuccessNotification = () => (
  <>
    <p className="body-text">Nothing else to do besides test the product!</p>
    <p className="body-text">We hope you are enjoying the formula.</p>
  </>
);

const FinalNotification = () => (
  <>
    <div className={styles.header}>
      <span role="img" aria-label="box" className={styles.icon}>
        🙌🏽
      </span>
      <h5>Thank you!</h5>
    </div>
    <p className="body-text">
      The testing you’ve done helps us develop better products, and will help
      other people like you find the right product for them.
    </p>
  </>
);

import React from "react";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

const _buildValidator = (rules) => {
  if (!rules) return null;

  return (value: any) => {
    let error = null;

    for (let i = 0; i < rules.length; i++) {
      if (!rules[i].validator(value)) {
        error = rules[i].message || "Error";
        break;
      }
    }

    return error;
  };
};

export default (fields) => {
  const config = {};

  const [errors, setErrors] = React.useState({});

  fields.forEach((field) => {
    const [value, setter] = React.useState(field.value);
    const validator = _buildValidator(field.validation);
    config[field.name] = {
      value,
      setter: field.parse ? (v) => setter(field.parse(v)) : setter,
      validator,
    };

    if (validator) {
      React.useEffect(() => {
        if (errors[field.name]) setErrors(omit(errors, [field.name]));
      }, [value]);
    }
  });

  const set = (field) => config[field].setter;
  const get = (field) => config[field]?.value;

  const validate = () => {
    const errorsFound = {};
    for (let field in config) {
      const { value, validator } = config[field];

      if (!validator) continue;

      const fieldError = validator(value);
      if (fieldError) {
        errorsFound[field] = fieldError;
      }
    }
    if (!isEmpty(errorsFound)) {
      setErrors(errorsFound);
      throw new Error("Validation Error");
    }
  };

  const reset = () => {
    fields.forEach((field) => {
      config[field.name].setter(field.value);
    });
  };

  return { get, set, validate, errors, reset, values: config };
};

export const IS_REQUIRED = (v) => !!v;

// @ts-nocheck

import React from "react";
import styles from "../__styles__/ReviewFlow.module.scss";

export default ({ product, count, total }) => {
  return (
    <div className={styles.productOverview}>
      <span className={styles.count}>
        Product {count} of {total}
      </span>
      <div className={styles.productCard}>
        <div className={styles.title}>
          <span
            className={styles.color}
            style={{ background: product ? product.get("color") : "none" }}
          ></span>{" "}
          {product ? product.get("name") : ""}
        </div>
        <div className={styles.description}>
          <p
            dangerouslySetInnerHTML={{
              __html: product
                ? product.get("description").replace(/\\\\?n/gi, "<br />")
                : "",
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

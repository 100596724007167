// @ts-nocheck

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import React from "react";

let app;

const init = () => {
  if (!app) {
    const firebaseConfig = {
      apiKey: "AIzaSyBhoQekJH-ILvYxJsHcwer8Moq5tvIaHQY",
      authDomain: "soft-services-beta.firebaseapp.com",
      databaseURL: "https://soft-services-beta.firebaseio.com",
      projectId: "soft-services-beta",
      storageBucket: "soft-services-beta.appspot.com",
      messagingSenderId: "165630078787",
      appId: "1:165630078787:web:d3105936752bee06fdc9f6",
      measurementId: "G-B2QTHW04KP",
    };

    app = firebase.initializeApp(firebaseConfig);
  }
};

const FirebaseContext = React.createContext({
  user: null,
  progress: [],
  notifications: [],
  initialized: false,
  db: null,
  storage: null,
  log: (message, type, data = {}) => {},
});

const FirebaseContextProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    let unsubscribeUserDoc;
    const unsubscribeAuthStateChanged = app
      .auth()
      .onAuthStateChanged(async (currentUser) => {
        if (!currentUser) {
          setUser(null);
          setInitialized(true);
          return;
        }

        const userDoc = app
          .firestore()
          .collection("users")
          .doc(currentUser.uid);

        await userDoc.set(
          {
            deleted: false,
            lastLogin: new Date(),
            ...(currentUser.phoneNumber
              ? { phoneNumber: currentUser.phoneNumber }
              : {}),
          },
          { merge: true }
        );

        unsubscribeUserDoc = userDoc.onSnapshot((snapshot) => {
          setUser(snapshot);
          setInitialized(true);
        });
      });

    return () => {
      unsubscribeAuthStateChanged();
      if (unsubscribeUserDoc) unsubscribeUserDoc();
    };
  }, []);

  const log = (message, type, data = {}) => {
    app
      .firestore()
      .collection("log")
      .add({ message, type, createdAt: new Date(), ...data });
  };

  return (
    <FirebaseContext.Provider
      value={{
        user,
        initialized,
        db: app.firestore(),
        storage: app.storage(),
        progress: [],
        notifications: [],
        log,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export { firebase, FirebaseContext, FirebaseContextProvider, init };
export const useFirebaseContext = () => React.useContext(FirebaseContext);

// @ts-nocheck

import React from "react";

import {
  IonContent,
  IonPage,
  IonSlides,
  IonSlide,
  useIonViewWillEnter,
  IonButton,
  NavContext,
} from "@ionic/react";
import { FirebaseContext } from "../contexts/firebase";
import Header from "../components/Header";
import { useProduct } from "../hooks/useProduct";
import { useTestContext } from "../contexts/test";
import styles from "./__styles__/Products.module.scss";
import { useReportsContext } from "../contexts/reports";

export default () => {
  const { test } = useTestContext();
  const { user } = React.useContext(FirebaseContext);

  const renderSlider = () => {
    if (!user.get("enrolledTestId")) {
      return (
        <div className={styles.container}>
          <p>More info coming soon!</p>
        </div>
      );
    }

    return <Slider products={test?.trial?.productIds || []} />;
  };

  return (
    <IonPage>
      <Header link />
      <IonContent fullscreen forceOverscroll={false} className={styles.content}>
        {renderSlider()}
      </IonContent>
    </IonPage>
  );
};

const ProductSlide = ({ productId }) => {
  const { navigate } = React.useContext(NavContext);
  const { product } = useProduct(productId);
  const { completeReviewReportProductIds } = useReportsContext();

  const isCompleted = completeReviewReportProductIds.includes(productId);

  return (
    <IonSlide>
      <div className={styles.slideInner + " body-text"}>
        <div className={styles.header + " body-text"}>
          <span
            className={styles.color}
            style={{ background: product ? product.get("color") : "none" }}
          ></span>{" "}
          {product ? product.get("name") : ""}
        </div>
        <div className={styles.description}>
          <p
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: product ? product.get("description") : "",
            }}
          ></p>
        </div>
        <div className={styles.instructions}>
          <h5 className="body-text">Instructions</h5>
          <p
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: product ? product.get("instructions") : "",
            }}
          ></p>
        </div>
        {!isCompleted && (
          <IonButton
            expand="block"
            className={styles.button}
            onClick={() => navigate(`/add-check-in/review/${productId}`)}
          >
            Review Product
          </IonButton>
        )}

        {isCompleted && <div className={styles.button}>Review Complete</div>}
      </div>
    </IonSlide>
  );
};

const Slider = ({ products }) => {
  const sliderRef = React.useRef();

  useIonViewWillEnter(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.update();
    }
  });

  return (
    <IonSlides
      pager={true}
      ref={sliderRef}
      class={styles.slider}
      options={{
        centeredSlides: true,
        spaceBetween: -80,
      }}
    >
      {products.map((productId) => (
        <ProductSlide key={productId} productId={productId} />
      ))}
    </IonSlides>
  );
};

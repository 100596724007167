import React from "react";

import { cfaSignOut } from "capacitor-firebase-auth";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import Header from "../components/Header";
import styles from "./__styles__/Info.module.scss";

export default () => {
  const logout = () => {
    cfaSignOut().subscribe();
  };

  return (
    <IonPage>
      <Header color="red" link />
      <IonContent className={styles.content} fullscreen>
        <div className={styles.container}>
          <h2>Body Project is making solutions for every body.</h2>
          <p className="body-text">
            If you need help, email{" "}
            <a href="mailto:help@bodyproject.co">help@bodyproject.co</a>.
          </p>
          <div className={styles.buttons}>
            <IonButton
              className={styles.button + " body-text"}
              onClick={logout}
              fill="clear"
            >
              Log Out
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
